<template>
	<div class="login-content">
		<p class="welcome-title">欢迎登录,理光技术中心!</p>
		<div class="login-box">
			<div class="login-box-left">
				<img class="login-logo" src="@/assets/images/logo2.png" alt="">
				<ul class="login-ul">
					<li>
						<span class="label"><span>*</span>用户姓名:</span>
						<input type="text" v-model="username" placeholder="请输入用户姓名">
					</li>
					<li>
						<span class="label"><span>*</span>手机号码:</span>
						<input type="text" v-model="tel" placeholder="请输入手机号码">
					</li>
					<li>
						<span class="label"><span>*</span>邮箱地址:</span>
						<input type="text" v-model="email" placeholder="请输入邮箱地址">
					</li>
					<li>
						<span class="label"><span>*</span>用户ID:</span>
						<input type="text" v-model="userid" placeholder="建议使用姓名拼音作为用户ID">
					</li>
					<li>
						<span class="label"><span>*</span>设置密码:</span>
						<input type="text" v-model="apassword" placeholder="请设置密码">
					</li>
					<li>
						<span class="label"><span>*</span>确认密码:</span>
						<input type="text" v-model="bpassword" placeholder="请确认密码">
					</li>
					<li>
						<span class="label"><span>*</span>所属区域:</span>
						<select name="" id="" v-model="area">
							<option value=""></option>
						</select>
					</li>
					<li>
						<span class="label"><span>*</span>所属公司:</span>
						<input type="text" v-model="company" placeholder="请输入公司名称">
					</li>
				</ul>
			</div>
			<img class="login-box-right" src="@/assets/images/beijing.png" alt="">
		</div>
		<p class="banquan">版权所有 © 2021理光（中国）投资有限公司</p>
		<p class="beian">备案编号：沪ICP备08111514号</p>
	</div>
</template>
<script>
export default {
	data() {
		return {
			username: '',
			tel: '',
			email: '',
			userid: '',
			apassword: '',
			bpassword: '',
			area: '',
			company: '',
		}
	}
}
</script>
<style lang="scss" scoped>
.lh-17{
	line-height: 17px;
}
.login-content{
	position: absolute;
	width: 100%;
	height: 100%;
	background: url('~@/assets/images/beijing2.png') no-repeat;
	background-color: #ECF7FF;
	background-size: contain;
	background-position: center;
	.welcome-title{
		position: absolute;
		right: 5%;
		top: 5%;
		font-size: 22px;
		font-weight: 500;
		color: #117FFC;
	}
	.login-box{
		display: flex;
		position: absolute;
		top: 14%;
		left: 18%;
		width: 62%;
		height: 70%;
		border-radius: 20px;
		background-color: #fff;
		.login-box-left{
			flex: 3;
			.login-logo{
				display: block;
				width: 35%;
				margin: 7% auto;
			}
			.login-ul{
				width: 70%;
				margin: 0 auto;
				li{
					position: relative;
					list-style: none;
					margin-bottom: 20px;
					display: flex;
					.label{
						width: 100px;
						line-height: 36px;
						span{
							color: red;
						}
					}
					input,
					select{
						flex: 1;
						height: 36px;
						box-sizing: border-box;
						padding-left: 10px;
						font-size: 14px;
						outline: none;
						border: 1px solid #ddd;
						border-radius: 5px;
					}
				}
			}
		}
	}
}
.banquan{
	text-align: center;
	color: #A8A8A8;
	position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -159px;
}
.beian{
	text-align: center;
	color: #0067FF;
	position: absolute;
    bottom: 16px;
    left: 50%;
    margin-left: -114px;
}
</style>
